<template>
  <div class="data-picker-field">
    <div class="label">{{ $t('color') }}</div>
    <v-menu
      v-model="menu"
      top
      nudge-bottom="105"
      nudge-left="16"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <div :style="swatchStyle" v-on="on" />
      </template>
      <v-card>
        <v-card-text class="pa-0">
          <v-color-picker v-model="color" />
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: String
  },
  data() {
    return {
      color: this.value || 'transparent',
      menu: false
    }
  },
  computed: {
    swatchStyle() {
      return {
        width: '93%',
        height: '22px',
        backgroundColor: this.color,
        borderRadius: '5px',
        cursor: 'pointer',
        borderColor: 'transparent',
        margin: '8px auto 7px auto'
      }
    }
  },
  watch: {
    color(newValue) {
      this.$emit('input', newValue)
    },
    value() {
      this.color = this.value
    }
  }
}
</script>
<style lang="scss">
.data-picker-field {
  border-radius: 4px;
  border: 1px solid #75757599;
  position: relative;

  .label {
    height: 17px;
    line-height: 20px;
    letter-spacing: normal;
    position: absolute;
    font-size: 12px;
    top: -10px;
    left: 9px;
    background: #fff;
    padding: 0px 3px 0px 3px;
  }
}
</style>
